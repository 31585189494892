import CustomHead from '@xFrame4/components/next/CustomHead';
import Layout from '@components/donkey/layout/Layout';
import config from '@config';
import { useAppActionDispatch } from '@redux/hooks';
import { setAppLanguage } from '@redux/layoutSlice';
import { useTranslate } from '@xFrame4/components/Hooks';
import { FunctionComponent, useEffect } from 'react';
import PixieList from '@components/donkey/pixies/PixieList';

interface HomePageProps
{
    
}

const HomePage: FunctionComponent<HomePageProps> = (props) =>
{
    const t = useTranslate();
    const dispatch = useAppActionDispatch();

    /** Set the global app language with Redux */
    useEffect(() => {
        dispatch(setAppLanguage(config.defaultLanguage))
    }, []);

    /** Render */
    return (
        <>
            <CustomHead 
                metaData={{
                    title: t('PIXIES')
                }}
            />

            <Layout>
                <PixieList language={config.defaultLanguage.code} />
            </Layout>
        </>
    );
}

export default HomePage;