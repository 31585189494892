import Pixie from '@business/pixies/Pixie';
import { useTranslate } from '@xFrame4/components/Hooks';
import { useLoad } from '@xFrame4/components/Hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import PixieThumbnail from './PixieThumbnail';

interface PixieListProps
{
    language: string;
}

const PixieList: FunctionComponent<PixieListProps> = (props) =>
{   
    const t = useTranslate();
    const [pixies, setPixies] = useState<Pixie[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false); 
    const [endCursor, setEndCursor] = useState<string>();
    const lazyLoadThreshold = 300;
    const [triggerLazyLoading, setTriggerLazyLoading] = useState<boolean>(false);
    
    /** Loads the pixies for the language */
    const loadPixies = async () =>
    {
        if (isLoading) return;

        setIsLoading(true);

        let pixieResult = await Pixie.manager.load({ translation_Language: props.language }, { first: 12, after: endCursor });
        console.log(pixieResult);
        if (pixieResult != null)
        {
            setPixies(endCursor === undefined ? pixieResult.entities : [...pixies, ...pixieResult.entities]);
            setEndCursor(pixieResult.paginationInfo.endCursor);
        }

        setIsLoading(false);
        setTriggerLazyLoading(false);
    }

    /** 
     * First time load or reset. 
     * Setting the endCursor to undefined will clear the pixies and restart loading from 0.
    */
    useLoad(async () => {
        if (endCursor === undefined) await loadPixies();
    }, [endCursor]);

    /** Lazy loading */
    useEffect(() => {
        const onScroll = () =>
        {
            let isAboveTreshold = document.body.clientHeight > window.innerHeight && window.innerHeight + window.scrollY > document.body.clientHeight - lazyLoadThreshold;
            setTriggerLazyLoading(isAboveTreshold);
        };
        document.addEventListener("scroll", onScroll);

        //cleanup: remove scroll event listener
        return () =>
        {
            document.removeEventListener("scroll", onScroll);
        };
    }, []);

    useEffect(() => {
        if (triggerLazyLoading && !isLoading && endCursor !== undefined)
        {
            loadPixies();
        }
    }, [triggerLazyLoading]);

    /** Language change: setting the endCursor to undefined will cause a reload (the pixies will be cleared in loadPixies). */
    useEffect(() => {
        setEndCursor(undefined);
    }, [props.language]);

    /** Pixie thumbnails */
    let cmpPixieThumbnails = pixies.map(pixie => 
        <PixieThumbnail 
            key={pixie.id}
            pixie={pixie} 
            language={props.language}
        />
    );

    /** Render */
    return (
        <>
            <div className="row">
                {cmpPixieThumbnails}
            </div>
            <div className="infinite-scroll-loading text-center py-3" style={{ display: isLoading ? "block" : "none" }}>
                    <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        </>
    );
}

export default PixieList;